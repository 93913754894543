import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function SecretSantaProgramList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        // { key: "select", sortable: false, label: "Select" },
        {
            key: "start_date",
            sortable: false,
            label: "Start Date"
        },
        { key: "year", sortable: true, label: "Year" },
        { key: "gift_price_limit", sortable: true, label: "Gift Price Limit" },
        { key: "invitation_type", sortable: true, label: "Invitation type" },
        { key: "participants_count", sortable: true, label: "Participants" },
        { key: "is_started", sortable: true, label: "Is Started" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalSecretSantaProgramList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const secret_santa_programsMetaData = ref([]);
    const SecretSantaProgramStatusOption = ref([]);
    const categoryFilter = ref(null);
    const SecretSantaProgramCategoriesOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalSecretSantaProgramList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [currentPage, perPage, searchQuery, statusFilter, categoryFilter],
        () => {
            refetchData();
        }
    );

    const fetchSecretSantaProgramList = (ctx, callback) => {
        store
            .dispatch("app-secret_santa_programs/fetchSecretSantaProgramList", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                category: categoryFilter.value
            })
            .then(response => {
                const SecretSantaProgram = response.data.data;
                const { total } = response.data.meta;
                SecretSantaProgramStatusOption.value = response.data.status;
                SecretSantaProgramCategoriesOption.value = response.data.categories;

                callback(SecretSantaProgram);
                totalSecretSantaProgramList.value = total;
                secret_santa_programsMetaData.value = SecretSantaProgram;
            })
            .catch(error => {
                console.error(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching Secret Santa Programs",
                        icon: "AlertTriangleIcon",
                        variant: "danger"
                    }
                });
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == "pending") return "primary";
        if (status == "resolved") return "info";
        if (status == "closed") return "success";
        return "danger";
    };

    return {
        fetchSecretSantaProgramList,
        tableColumns,
        perPage,
        currentPage,
        totalSecretSantaProgramList,
        secret_santa_programsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        SecretSantaProgramStatusOption,
        statusFilter,
        categoryFilter,
        SecretSantaProgramCategoriesOption
    };
}
