<template>
    <b-card
      class="chat-widget"
      no-body
    >
      <b-card-header>
        <div class="d-flex align-items-center">
          <b-avatar
            size="34"
            :src="ChatDetails.avatar"
            class="mr-50"
          />
          <h5 class="mb-0">
            {{ChatDetails.name}}
          </h5>
        </div>
        <!-- <feather-icon
          icon="MoreVerticalIcon"
          size="18"
        /> -->
      </b-card-header>
  
      <section class="chat-app-window">
        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="chatData"
            :profile-user-avatar="ChatDetails.sender_avatar"
          />
        </vue-perfect-scrollbar>
  
        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-textarea
              v-model="chatInputMessage"
              placeholder="Type your message"
            />
          </b-input-group>
          <b-button
            variant="danger"
            type="submit"
            pill
          >
          <b-img :src="require('@/assets/images/secret-santa/post-cmt-white.svg')" width="26"></b-img>
          </b-button>
        </b-form>
      </section>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BAvatar, BForm, BFormInput, BInputGroup, BButton,
  } from 'bootstrap-vue'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';
  import ChatLog from '@/views/chat/ChatLog.vue';
  import axios from "@axios";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  export default {
    components: {
      BCard,
      BCardHeader,
      BAvatar,
      BForm,
      BFormInput,
      BInputGroup,
      BButton,
  
      // SFC
      ChatLog,
  
      // 3rd party
      VuePerfectScrollbar,
    },
    props:{
        ChatDetails:{
            type: Object,
            required: true,
        },
    },
    data() {
      return {
        perfectScrollbarSettings: {
          maxScrollbarLength: 150,
          wheelPropagation: false,
        },
        chatInputMessage: '',
        chatData: {
          chat: {
            userId: this.ChatDetails.senderId, 
            unseenMsgs: 0, 
            chat: this.ChatDetails.conversation,
            },
          contact: {
            id: this.ChatDetails.contactId,
            fullName:this.ChatDetails.name,
            avatar: this.ChatDetails.avatar,
            status: 'away',
          },
        },
      }
    },
    mounted() {
      this.psToBottom()
    },
    methods: {
      sendMessage() {
        if(this.chatInputMessage && this.chatInputMessage != ''){
            var node = {
                message: this.chatInputMessage,
                time: new Date(),
                senderId: this.ChatDetails.senderId,
                programId:this.ChatDetails.programId,
            };
            var data = {
                message: this.chatInputMessage,
                time: new Date(),
                senderId: this.ChatDetails.senderId,
                programId:this.ChatDetails.programId,
            }
            data.to = this.ChatDetails.name;
            if(data.senderId == 11){
              data.senderId = this.ChatDetails.contactId;
            }
            axios.post("/"+this.ChatDetails.type+"/send-message",data)
            .then((response) => {
                if(response.status == '201'){
                  this.chatData.chat.chat.push(node)
                  this.chatInputMessage = ''
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Message sent successfully.',
                    icon: 'EditIcon',
                    variant: 'primary',
                    },
                  });
                }
                else{
                    this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Unable to send message to santa',
                        icon: 'EditIcon',
                        variant: 'danger',
                        text: response.data.message,
                    },
                    })
                }
            });
            // Update scroll position
            // Scroll to bottom
            this.$nextTick(() => {
                this.psToBottom()
            })
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
            title: 'Enter text message and click send',
            icon: 'EditIcon',
            variant: 'danger',
            },
          });
        }
        
        
      },
      psToBottom() {
        console.log(this.$refs.refChatLogPS);
        const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
        scrollEl.scrollTop = scrollEl.scrollHeight
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import "@core/scss/base/pages/app-chat-list.scss";
  </style>
  