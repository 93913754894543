<template>
    <b-card
      :img-src="require('@/assets/images/secret-santa/child-bg.svg')"
      img-alt="Profile Cover Photo"
      img-top
      class="card-profile shadow-none p-1"
      title="Your Little Elf !"
    >
      <div class="profile-image-wrapper">
        <div class="profile-image p-0">
          <b-avatar
            size="114"
            variant="light"
            :src="child.avatar"
          />
        </div>
      </div>
      <h3>{{child.name}}</h3>
      <h6 class="text-muted">
        {{child.email}}
      </h6>
      <b-badge
        class="profile-badge"
        variant="light-primary"
      >
      <!-- {{child.designation}} -->
      </b-badge>
      <!-- follower projects rank -->
      <div class="d-flex align-items-center justify-content-center mt-2 pt-1">
        <b-button  href="#" variant="danger" pill
        v-b-modal.task > Give a Task</b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary" pill
            class="btn-icon ml-1"
            v-if="program.childWishList && program.childWishList.wishlist.length"

            v-b-tooltip.hover.v-dark v-b-tooltip.hover.right="'View Wishlist'"
            v-b-modal.view-wishlist
            >
            <feather-icon icon="HeartIcon" stroke="#455A64" />
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary" pill
            class="btn-icon ml-1"
            v-if="program.childWishList && program.childWishList.delivery_address"

            v-b-tooltip.hover.v-dark v-b-tooltip.hover.right="'View Delivery Address'"
            v-b-modal.view-delivery-address
            >
            <feather-icon icon="MenuIcon" stroke="#455A64" />
        </b-button>
      </div>
      <b-modal v-if="child"
            id="task"
            ref="task"
            ok-title="Assign Task"
            centered
            size="lg"
            @ok="assignTask"
            cancel-variant="outline-danger"
            @cancel="resetModal"
            @close="resetModal"
            :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" header-class="sticky-top"
            >
            <template #modal-header="{ close }">
                <div class="next-expand d-flex align-items-center justify-content-between w-100">
                  <h3 class="mb-0">Give a task to your little elf</h3>
                  <b-link class="fullscreen-icon"
                    @click="fullScreen = !fullScreen">
                    <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark
                            title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')"  />
                            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark
                            title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  />
                  </b-link>
                </div>
                <feather-icon class="cursor-pointer floating-close close" icon="XIcon" size="20" @click="close()" />
              </template>
            <b-row>
                <!-- <b-col md="12">
                    <h3>Task Deatils</h3>
                </b-col>     -->
                <b-col md="12">
                    <b-form-group label="Task Title" label-for="title">
                    <b-form-input
                        id="title"
                        v-model="task.title"
                        type="text"
                        placeholder="Example - Buy Toothbrush"
                        required
                    />
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group label="Task Description" label-for="description">
                        <QuillInput
                        :value.sync="task.description"
                        placeholder="Run to nearby store and buy a toothbrush and upload a picture of it. :)"
                        :toolbar="true"
                        className="task-editor"
                        :mentionList="[]"
                        required
                    />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal v-if="child"
            id="view-wishlist"
            ref="view-wishlist"
            centered
            size="lg"
            cancel-variant="outline-danger"
            :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" header-class="sticky-top"
            >
            <template #modal-header="{ close }">
                <div class="next-expand d-flex align-items-center justify-content-between w-100">
                  <h3 class="mb-0">Your Little Elf's Wishlist</h3>
                  <b-link class="fullscreen-icon"
                    @click="fullScreen = !fullScreen">
                    <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark
                            title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')"  />
                            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark
                            title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  />
                  </b-link>
                </div>
                <feather-icon class="cursor-pointer floating-close close" icon="XIcon" size="20" @click="close()" />
              </template>
            <b-row>
                <b-col md="12">
                    <h4 class="mb-1">Wishlist</h4>
                    <span v-if="program.gift_price_limit">Please note that gift price limit is ${{program.gift_price_limit}}</span>
                </b-col>    
                <b-col md="12">
                    <b-list-group>
                        <b-list-group-item v-for="wish in program.childWishList.wishlist">{{wish}}</b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal v-if="child"
            id="view-delivery-address"
            ref="view-delivery-address"
            centered
            size="lg"
            cancel-variant="outline-danger"
            :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" header-class="sticky-top"
            >
            <template #modal-header="{ close }">
                <div class="next-expand d-flex align-items-center justify-content-between w-100">
                  <h3 class="mb-0">Your Little Elf's Delivery Address for Gift Exchange</h3>
                  <b-link class="fullscreen-icon"
                    @click="fullScreen = !fullScreen">
                    <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark
                            title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')"  />
                            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark
                            title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  />
                  </b-link>
                </div>
                <feather-icon class="cursor-pointer floating-close close" icon="XIcon" size="20" @click="close()" />
              </template>
            <b-row>
                <b-col md="12">
                    <h4 class="mb-1">Delivery Address</h4>
                </b-col>    
                <b-col md="12">
                    <b-list-group>
                        <b-list-group-item v-for="value,key in program.childWishList.delivery_address">{{key+" : "+value}}</b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-modal>

      <!--/ follower projects rank -->
    </b-card>
  </template>
  
<script>
import { BCard, BAvatar, BBadge,BModal } from 'bootstrap-vue'
import QuillInput from "@/components/QuillInput.vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  export default {
    components: {
      BCard,
      BBadge,
      BAvatar,
      BModal,
      QuillInput
    },
    props:{
        child:{
        type: Object,
        required: true,
        },
        program:{
        type: Object,
        required: true,
        },
    },
    data(){
        return {
            fullScreen: false,
            task:{
                title:'',
	            description:'',
                hashid:'',
                secret_santa_program_id:'',
            },
        }
    },
    created(){
    },
    methods:{
        assignTask(){
            if(this.task.title == ''){
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Task Title is Required',
                        icon: 'EditIcon',
                        variant: 'danger',
                    },
                });
            }
            else if(this.task.discription == ''){
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Task Description is Required',
                        icon: 'EditIcon',
                        variant: 'danger',
                    },
                });
            }
            else{
                if(this.task.hashid != ''){

                }else{
                    this.task.secret_santa_program_id = this.program.hashid;
                    axios.post("/secret-santa-task",this.task)
                    .then((response) => {
                        if(response.status == '201'){
                            this.$emit('refresh-task-list')
                            this.$refs['task'].hide()
                            this.resetModal();
                            this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Task details updated successfully',
                                icon: 'EditIcon',
                                variant: 'primary',
                            },
                        });
                        }else{
                            this.$toast({
                                component: ToastificationContent,
                                    props: {
                                        title: 'Unable to update task details',
                                        icon: 'EditIcon',
                                        variant: 'danger',
                                    },
                            });
                        }
                    })
                    .catch((error) => {
                        for(var err in error.response.data.errors) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error in '+err,
                                icon: 'EditIcon',
                                variant: 'danger',
                                text: error.response.data.errors[err][0]
                            },
                            })
                        }
                    });
                }
                
            }
        },
        resetModal(){
            this.task = {
                title:'',
	            description:'',
                hashid:'',
                secret_santa_program_id:''
            };
        },
        
    }
  }
  </script>
  